import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tabs",
      "style": {
        "position": "relative"
      }
    }}>{`Tabs`}</h1>
    <p>{`O uso de abas é indicado para organizar conteúdos similares em uma mesma página, permitindo que o conteúdo possa ser visualizado sem ter que navegar para outra página.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Utilize no máximo duas palavras para o título.`}</li>
      <li parentName="ul">{`Utilize abas quando o conteúdo e estrutura das telas forem diferentes.`}</li>
      <li parentName="ul">{`Utilize no máximo 6 abas, isso ajuda a manter a interface organizada e reduz a carga cognitiva do usuário.`}</li>
      <li parentName="ul">{`Abas com conteúdos relacionados devem ser mantidas próximas uma das outras.`}</li>
      <li parentName="ul">{`Não é recomendado utilizar abas como componente de filtro.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplos",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplos`}</h2>
    <Demo src='pages/components/tabs/Tab' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      